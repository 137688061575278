import styles from './TravelFilterInfoAndReset.module.scss'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { resetTravelFilter, selectTravelFilter } from '@/features/travelFilter'

const TravelFilterInfoAndReset = () => {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()
  const filterState = useAppSelector(selectTravelFilter)

  const resetFilter = () => {
    dispatch(resetTravelFilter({}))
  }

  return (
    <>
      <div className={styles.resultInfo}>{t('travelFilter.resultInfo', { count: filterState.counts.filteredTotal })}</div>
      <button type="button" className={`btn-unstyled ${styles.resetBtn}`} onClick={resetFilter} data-tr-click="travel_filter:filter_reset:click">
        &raquo; {t('travelFilter.button.reset')}
      </button>
    </>
  )
}

export default TravelFilterInfoAndReset
